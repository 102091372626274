const ACCOMPANIMENT = [{
  // 订单管理
  path: '/orderManageList',
  name: 'orderManageList',
  // 按需加载路由
  component: () => import('@/views/accompaniment/orderManage/orderManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}, {
  // 套餐分类管理
  path: '/taxonomy',
  name: 'taxonomy',
  // 按需加载路由
  component: () => import('@/views/accompaniment/taxonomy/taxonomy'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}, {
  // 套餐管理
  path: '/comboManage',
  name: 'comboManage',
  // 按需加载路由
  component: () => import('@/views/accompaniment/comboManage/comboManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}, {
  // 提现管理
  path: '/drawalsManage',
  name: 'drawalsManage',
  // 按需加载路由
  component: () => import('@/views/accompaniment/drawalsManage/drawalsManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}, {
  // 发放明细
  path: '/lssuanceManage',
  name: 'lssuanceManage',
  // 按需加载路由
  component: () => import('@/views/accompaniment/lssuance/lssuanceManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}, {
  // 医院管理
  path: '/hospitalManage',
  name: 'hospitalManage',
  // 按需加载路由
  component: () => import('@/views/accompaniment/hospitalManage/hospitalManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}, {
  // 代理商列表
  path: '/agentlistManage',
  name: 'agentlistManage',
  // 按需加载路由
  component: () => import('@/views/accompaniment/agentlistManage/agentlistManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}, {
  // 提现审核
  path: '/PayoutsManage',
  name: 'PayoutsManage',
  // 按需加载路由
  component: () => import('@/views/accompaniment/PayoutsManage/PayoutsManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}, {
  // 入账明细
  path: '/arrivedManage',
  name: 'arrivedManage',
  // 按需加载路由
  component: () => import('@/views/accompaniment/arrivedManage/arrivedManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}]
export default ACCOMPANIMENT
